import { Link, useStaticQuery } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { ReviewForInModal } from './reviews-all-modal-popup'
import { Carousel } from '@mantine/carousel';

export const HomePageReviews = () => {
    //gatsby static data query
    const data = useStaticQuery(graphql`

    query {
        allContentfulTestimonial(
            sort: { fields: date, order: DESC }
            limit: 20
          ) {
            edges {
              node {
                id
                reviewTitle
                featuredReview
                date
                reviewer 
                userPhoto {
                  gatsbyImageData(width: 65)
                }
                reviewBody {
                  id
                  reviewBody
                }  
              }
            }
          }
    }
    `)

    const reviews = data.allContentfulTestimonial.edges


    return (

        <div className="max-w-7xl mx-auto  pl-6 sm:px-6 lg:px-8 -mt-8 pb-8 ">
            <Carousel
                className='rounded-xl  overflow-hidden'

                height={230}
                slideGap="md"
                slideSize={'33%'}
                breakpoints={[
                    { maxWidth: 'lg', slideSize: '33%' },
                    { maxWidth: 'md', slideSize: '50%' },
                    { maxWidth: 'sm', slideSize: '85%' },
                ]}

                align="start"
                slidesToScroll={1}
            >
                {reviews.map((review, i) => {
                    return (
                        <Carousel.Slide>
                            <ReviewForInModal item={review} className="rounded-xl  overflow-y-auto" clamp={4} />
                        </Carousel.Slide>

                    )
                })}
            </Carousel>
            {/*     
            <div className="flex flex-col items-center justify-center w-full z-50 bg-white    overflow-auto"> 
                <div className='flex flex-nowrap py-8'>
                    {reviews.map((review, i) => {
                        return (
                            <div className="w-1/5 shrink-0 flex flex-col items-center justify-center w-full">
                                <ReviewForInModal item={review} className="rounded-xl h-60" />
                            </div>
                        )
                    })}
                </div>
            </div> */}
        </div>
    )
}
